import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser'
import { useRef } from 'react';
import { themeContext } from '../../Context';
import { useContext } from 'react';
const Contact = () => {
    const form = useRef();
const [done,setDone]=useState(false)
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_dg8s8qo', 'template_lno01gl', form.current, 'CEfRkc0MirX603BHm')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    };
    const theme=useContext(themeContext)
    const darkMode=theme.state.darkMode;
  return (
    <div className='contact'id='contact'>
        <div className='c-left'>
            <div className='awesom'>
            <span style={{ color: darkMode ? "white" : "" }}>Get in Touch</span>
           <br/>
            <span>Contact me</span>
            <div
            className='blur s-blur'
            style={{background:'#ABF1FF94'}}
            >

            </div>
            </div>
        </div>
        <div className='c-right'>
            <form ref={form}onSubmit={sendEmail}>
                <input type='text' name='user_name' className='user' placeholder='Name'/>
                <input type='email' name='user_email' className='user' placeholder='Email'/>
                <textarea name='message' className='user' placeholder='Message'/>
                <input type='submit' className='button' value="Send"/>
                <span>{done && "Thanks for contacting me !"}</span>
                <div
                className='blur c-blur'
                style={{background:"var(--purple)"}}
                >

                </div>
            </form>
        </div>
    </div>
  )
}

export default Contact



