import React from 'react'
import './Navbar.css'
import Toggle from '../Toggle/Toggle'
import { Link } from 'react-scroll'
const Navbar = () => {
  return (
    <div className='n-wrapper'>
<div className='n-left'>
    <div className='n-name'>Abdul Rehman</div>
    <Toggle/>
</div>
<div className='n-right'>
    <div className='n-list'>
        <ul style={{listStyleType:"none"}}>
            <li>
                <Link
                to='home'
                span={true}
                smooth={true}
                >Home</Link>
                </li>
            <li>
            <Link
                to='services'
                span={true}
                smooth={true}
                >Services</Link>
                </li>
            <li>
            <Link
                to='experiences'
                span={true}
                smooth={true}
                >Experiences</Link>
                </li>
            <li>
            <Link
                to='portfolio'
                span={true}
                smooth={true}
                >Portfolio</Link>
                </li>
            <li>
            <Link
                to='testimonial'
                span={true}
                smooth={true}
                >Testimonials</Link>
                </li>
        </ul>
    </div>
    <button className='button n-button'>
        <Link
        to='contact'
        span={true}
        smooth={true}
        >
        Contact
        </Link>
        </button>
</div>
    </div>
  )
}

export default Navbar